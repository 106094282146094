import React from 'react'
import { Helmet } from "react-helmet"
import { Link, graphql } from 'gatsby'
import Img from "gatsby-image"
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import BackgroundImage from 'gatsby-background-image'
import GoogleReviews from '../components/google_reviews_slider'
import Copyright from '../components/copyright'

function getSection (allMarkdownRemarkGroup, section) {
    let result = [];
	for (let edges of allMarkdownRemarkGroup) {
        for (let item of edges.edges) {
            if (item.node.frontmatter.section === section) {
                result.push(item.node)
            }
        }
    }
    result.sort(function(a, b) {
        return a.frontmatter.nav_order - b.frontmatter.nav_order;
    });
	return result;
}

const NavSection = (p) => <div className="grid grid__3 grid__gap--48 list">
    {p.navSectionItems.map( (p, i) => (<div key={i}>
        <Link to={p.fields.slug}>
            <Img fluid={p.frontmatter.image.childImageSharp.fluid} alt={p.frontmatter.title} />
            <h3>{p.frontmatter.title}</h3>
        </Link>
        </div>) )}
</div>

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Helmet>
                <link href="https://fonts.googleapis.com/css2?family=Oswald&display=swap" rel="stylesheet" />
            </Helmet>
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <div className="layout">
                <BackgroundImage fluid={entry.frontmatter.image.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                    <div className="title__gradient">
                        <div className="layout__container">
                            <div className="layout__wide title center">
                                <h1>{entry.frontmatter.heading}</h1>
                                <p>Phone us for a quick response and fast quote.<br />Call us now on <a href="tel:0800828299" title="0800 8282 99" rel="nofollow noopener">0800 8282 99</a>.</p>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            </div>
            <div className="layout grey">
                <div className="layout__container">
                    <div className="layout__wide">
                        <nav className="breadcrumb">
                            <p><Link to='/'>Home</Link> &nbsp;&rsaquo;&nbsp; <strong>{entry.frontmatter.title}</strong></p>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="layout body">
                <div className="layout__container">
                    <div className="layout__wide">
                        <NavSection navSectionItems={getSection(data.allMarkdownRemark.group, 'gallery')} />
                    </div>
                </div>
            </div>
            <GoogleReviews />
            <Copyright />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
        allMarkdownRemark {
            group(field: frontmatter___section) {
                edges {
                node {
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        section
                        nav_order
                        image {
                            childImageSharp {
                                fluid(maxWidth: 480 maxHeight: 480) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
                }
            }
        }
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
                section
                title
				page_title
                meta_description
                heading
                image {
                    childImageSharp {
                        fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                side_heading
                side_text {
                    text
                }
                side_image {
                    childImageSharp {
                        fluid(maxWidth: 640, maxHeight: 480) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
			}
			fields {
				slug
			}
			html
		}
	}
`